import classNames from "classnames";
import React, { useEffect, useState } from "react";

export const LuxOverlay = ({
  children,
  onHide,
  variant = "modal",
  canClickOutToDismiss,
}: {
  children: React.ReactNode;
  onHide: () => unknown;
  variant?: "panel" | "cmdk" | "modal" | "glass" | "big";
  canClickOutToDismiss: boolean;
}) => {
  const [pop, setPop] = useState(false);
  const onPop = () => {
    setPop(true);
    setTimeout(() => setPop(false), 300);
  };

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === "Escape" && canClickOutToDismiss) {
        event.stopPropagation();
        onHide();
      }
    };

    document.addEventListener("keydown", handleEscape);

    return () => {
      document.removeEventListener("keydown", handleEscape);
    };
  }, [onHide, canClickOutToDismiss]);

  return (
    <div
      key="backdrop"
      className={classNames("lux-overlay", variant, { pop })}
      // We need to do onMouseDown rather than onClick because onClick will
      // fire if your mouse goes up outside the modal. This can be triggered
      // by people who copy text and drag outside the modal while copying text.
      onMouseDown={canClickOutToDismiss ? onHide : onPop}
      // This is needed to prevent the click from bubbling up to an ancestor
      // We can't avoid this by putting the child in a portal since React
      // will bubble events based on the React tree.
      onClick={(e) => e.stopPropagation()}
    >
      {children}
    </div>
  );
};
