import { useCallback, useMemo, useState } from "react";

export type BooleanState = {
  value: boolean;
  setTrue: () => void;
  setFalse: () => void;
  setValue: (val: boolean) => void;
  toggle: () => void;
};

export const useBoolean = (initialValue = false): BooleanState => {
  const [value, setValue] = useState<boolean>(initialValue);
  const setTrue = useCallback(() => {
    setValue(true);
  }, [setValue]);
  const setFalse = useCallback(() => {
    setValue(false);
  }, [setValue]);
  const toggle = useCallback(() => {
    setValue((val) => !val);
  }, [setValue]);

  return useMemo(
    () => ({
      value,
      setTrue,
      setFalse,
      setValue,
      toggle,
    }),
    [value, setTrue, setFalse, setValue, toggle],
  );
};
