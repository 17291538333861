import { LuxButton } from "@lux/atoms/components/LuxButton";

import { SocialHead } from "./all-pages/SocialHead";
import { SimpleNav } from "./all-pages/TopNav";
import { LoadRoobert } from "@lux/atoms/components/LoadRoobert";

export const GlowError = ({ statusCode }: { statusCode: number }) => {
  if (statusCode >= 400 && statusCode < 500) {
    statusCode = 404;
  }

  let title = "Error";
  if (statusCode >= 500) {
    title = "Server Error";
  } else if (statusCode == 404) {
    title = "Page Not Found";
  }

  let description =
    "Looks like you have discovered a page that doesn’t exist or you don’t have access to.";
  if (statusCode >= 500) {
    description =
      "Oops! Something went wrong on our side. Sorry about that — please retry in a bit.";
  }

  return (
    <div className="error flex-center-center p-4 font-roobert grid-background">
      <SocialHead subtitle={title} />
      <LoadRoobert />

      <SimpleNav />

      <div className="flex-center flex-column">
        <img src={`/error/${statusCode == 404 ? 404 : 500}.png`} alt={title} />
        <h1>{title}</h1>
        <p className="fs-lg text-tertiary">{description}</p>
        <div className="mt-3">
          <LuxButton label="Return Home" href="/" rounded size="large" />
        </div>
      </div>

      <style jsx>{`
        .error {
          position: fixed;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          text-align: center;

          :global(.simple-top-nav) {
            position: fixed;
            top: 0;
            left: 0;
          }

          img {
            width: 96px;
            filter: drop-shadow(var(--shadow-xs));
          }

          h1 {
            margin: 2rem 0 1.5rem 0;
          }

          p {
            max-width: 450px;
          }
        }
      `}</style>
    </div>
  );
};
