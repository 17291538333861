import GlowLogo from "@glow/react/icons/GlowCoin.svg";
import classNames from "classnames";
import React from "react";

import { TwitterLink } from "@components/atoms/TwitterLink";
import { ResponsiveBreakpoint } from "@utils/StyleConstants";

export const SimpleNav = ({
  className,
  fixedOnDesktop,
}: {
  className?: string;
  fixedOnDesktop?: boolean;
}) => {
  return (
    <div
      className={classNames("simple-top-nav flex-center spread", className, {
        "fixed-desktop": fixedOnDesktop,
      })}
    >
      <a className="logo" href="/">
        <GlowLogo />
      </a>

      <TwitterLink />

      <style jsx>{`
        .simple-top-nav {
          padding: 1rem 1.25rem;
          width: 100%;

          &.fixed-desktop {
            position: fixed;
            top: 0;
            left: 0;

            @media (max-width: ${ResponsiveBreakpoint.small}) {
              position: relative;
            }
          }

          .logo {
            color: var(--tertiary-color);

            &:hover {
              color: var(--primary-color);
            }

            :global(svg) {
              width: 1.5rem;
              height: 1.5rem;
            }
          }
        }
      `}</style>
    </div>
  );
};
