import { Placement } from "@floating-ui/react-dom-interactions";
import React, { useImperativeHandle, useState } from "react";

import { LuxBaseMenu } from "./LuxMenu";

export const LuxTooltip = React.forwardRef(
  (
    {
      placement = "top",
      color = "inverted",
      maxWidth,
      children,
      content,
      forceHidden,
      useSafePolygon,
    }: {
      placement?: Placement;
      maxWidth?: string | number;
      color?: "default" | "inverted";
      children: JSX.Element;
      content: string | React.ReactNode | null;
      forceHidden?: boolean;
      useSafePolygon?: boolean;
    },
    ref: React.ForwardedRef<{ hide: () => unknown }>,
  ) => {
    const [show, setShow] = useState(false);

    useImperativeHandle(ref, () => ({
      hide: () => {
        setShow(false);
      },
    }));

    if (!content) {
      return <>{children}</>;
    }

    return (
      <LuxBaseMenu
        trigger={children}
        placement={placement}
        open={show && !forceHidden}
        setOpen={setShow}
        mode="hover"
        color={color}
        useSafePolygon={useSafePolygon}
      >
        <div className="lux-tooltip" style={{ maxWidth: maxWidth || "240px" }}>
          {content}

          <style jsx>{`
            .lux-tooltip {
              padding: 0.25rem 0.625rem;
              font-size: var(--font-size-xs);
              color: var(--primary-bg-color);
              white-space: pre-line;
            }
          `}</style>
        </div>
      </LuxBaseMenu>
    );
  },
);
