import React from "react";
import { LuxTooltip } from "./LuxTooltip";

export const OptionalTooltip = React.forwardRef(
  (
    {
      tooltipLabel,
      children,
    }: {
      tooltipLabel: React.ReactNode | null;
      children: React.ReactElement;
    },
    ref: React.ForwardedRef<{ hide: () => unknown }>,
  ) => {
    if (!tooltipLabel) {
      return <>{children}</>;
    }

    return (
      <LuxTooltip ref={ref} content={tooltipLabel}>
        {children}
      </LuxTooltip>
    );
  },
);
