import { useDarkMode } from "@lux/atoms/hooks/useDarkMode";
import { useIsMounted } from "@lux/atoms/hooks/useIsMounted";
import TwitterLightIcon from "@glow/react/icons/TwitterGradient.svg";
import TwitterDarkIcon from "@glow/react/icons/TwitterGradientDark.svg";
import React from "react";

export const TwitterLink = ({ size = "1.5rem" }: { size?: string }) => {
  const { isDarkMode } = useDarkMode();
  const isMounted = useIsMounted();

  return (
    <a
      className="twitter"
      href="https://twitter.com/glowwallet"
      target="_blank"
    >
      {isDarkMode && isMounted ? (
        <TwitterDarkIcon key="dark" />
      ) : (
        <TwitterLightIcon key="light" />
      )}

      <style jsx>{`
        .twitter {
          :global(svg) {
            width: ${size};
            height: ${size};
          }

          :global(svg path) {
            transition: var(--transition);
          }

          &:hover {
            :global(svg path) {
              fill: var(--secondary-color);
            }
          }
        }
      `}</style>
    </a>
  );
};
