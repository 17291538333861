import React, { Ref } from "react";

import { createUseAppContext } from "../utils/context";

export type LinkProps = {
  href: string;
  className?: string;
} & Omit<
  React.DetailedHTMLProps<
    React.AnchorHTMLAttributes<HTMLAnchorElement>,
    HTMLAnchorElement
  >,
  "ref"
> & { ref?: Ref<HTMLAnchorElement> };

export const ComponentsOverridesContext = React.createContext<{
  Link:
    | React.ComponentType<LinkProps>
    | ((
        props: LinkProps & React.RefAttributes<HTMLAnchorElement>,
      ) => React.ReactElement | null);
  HOST: string;
  baseGlowUrl?: string;
} | null>(null);

export const useComponentsOverrides = createUseAppContext(
  ComponentsOverridesContext,
  "ComponentsOverridesContext",
);
