import ImgixClient from "@imgix/js-core";
import { croppedUnsplashImage, isUnsplashImage } from "./unsplash";
import { getImageUrl } from "./cdn";

const imgixClient = new ImgixClient({
  domain: "images.glow.app",
  // We're not supposed to put this here, but for now it's fine...
  secureURLToken: "mRePDNnrwM9cys5t",
});

/**
 * This is different than Luma's method since it uses Imgix for foreign
 * images. Luma doesn't need Imgix since all images are in our storage.
 */
export const getGlowImageUrl = ({
  url,
  width,
  height,
  mime_type,
  quality = 85,
  dpr = 2,
  blur,
  fit = "cover",
  noAnimation,
  format = "auto",
}: {
  url: string | null;
  width: number;
  height?: number;
  mime_type?: string | null;
  quality?: number;
  blur?: number;
  dpr?: number;
  fit?: "crop" | "cover" | "scale-down" | null;
  noAnimation?: boolean;
  /**
   * - "auto" = default, choose best format based on browser support
   * - "legacy" = JPG or PNG
   */
  format?: "auto" | "legacy";
}): string | undefined => {
  if (!url) {
    return;
  }

  // We have already done cropping, so respect the cropping that is already applied
  if (
    url.startsWith("https://cdn.lu.ma/cdn-cgi/image/") ||
    url.startsWith("https://images.lumacdn.com/cdn-cgi/image/") ||
    url.startsWith("https://social-images.lu.ma/cdn-cgi/image/") ||
    url.startsWith("https://cdn.glow.app/cdn-cgi/image/") ||
    url.startsWith("https://images.glow.app/") ||
    url.startsWith("http://localhost")
  ) {
    return url;
  }

  // If it's a Data URL we won't do any processing
  if (url.startsWith("data:")) {
    return url;
  }

  // Don't use Cloudflare resizing for gifs to avoid animated gifs falling foul
  // of max frame/size limits.
  if (mime_type === "image/gif" || url.endsWith(".gif")) {
    return url;
  }

  if (isUnsplashImage(url)) {
    return croppedUnsplashImage({ src: url, width, height, dpr });
  }

  try {
    const parsedUrl = new URL(url);

    // We can only do cropping on our domain
    if (
      parsedUrl.origin !== "https://social-images.lu.ma" &&
      parsedUrl.origin !== "https://cdn.lu.ma" &&
      parsedUrl.origin !== "https://images.lumacdn.com" &&
      parsedUrl.origin !== "https://cdn.glow.app"
    ) {
      let imgixFit: ImgixFit | null = null;
      if (fit === "scale-down") {
        imgixFit = "max";
      } else if (fit === "crop") {
        imgixFit = "crop";
      } else if (fit === "cover") {
        imgixFit = "crop";
      }
      return buildImgixUrl({
        url,
        height: height || null,
        width,
        dpr,
        fit: imgixFit,
      })!;
    }

    return getImageUrl({
      url,
      width,
      height,
      mime_type,
      quality,
      dpr,
      blur,
      fit,
      noAnimation,
      format,
    });
  } catch (e) {
    return;
  }
};

type ImgixFit = "max" | "clip" | "crop" | "fill";

/**
 * https://docs.imgix.com/apis/rendering
 */
const buildImgixUrl = ({
  url,
  height,
  width,
  dpr = 2,
  blur,
  fit,
}: {
  url: string | null | undefined;
  height: number | null;
  width: number;
  dpr?: number;
  blur?: number;
  fit?: ImgixFit | null | undefined;
}): string | null => {
  if (!url) {
    return null;
  }

  const urlObject = new URL(url);
  // Imgix does not support proxying images on naked URLs like
  // https://bafybeibcvbimejidwvgdoudrcef7btjkszvkr7zur5efg6gljlk4vft2fu.ipfs.dweb.link/?ext=png
  if (urlObject.pathname === "/") {
    urlObject.search = `__glowcdn=1`;
  }

  return imgixClient.buildURL(urlObject.toString(), {
    w: width,
    h: height,
    // Imgix blur is on a scale of 0-2000 but CloudFlare uses 0-250.
    blur: blur ? blur * 8 : undefined,
    dpr,
    fit,
  });
};
