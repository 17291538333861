import { LazyMotion } from "framer-motion";
import React from "react";

/**
 * The `framer-motion` library is pretty large (> 50kb). If we import it in
 * _app.tsx then we will be loading it for every page load.
 *
 * This allows us to lazy load most of the framer motion library after initially
 * rendering and hydrating the page.
 *
 * https://www.framer.com/motion/guide-reduce-bundle-size/
 */
export const FramerProvider = ({
  children,
  strict = true,
}: {
  children: React.ReactNode;
  strict?: boolean;
}) => {
  return (
    <LazyMotion
      features={() =>
        // Make sure to return the specific export containing the feature bundle.
        import("./framer-lazy-features").then((res) => res.default)
      }
      strict={strict}
    >
      {children}
    </LazyMotion>
  );
};
